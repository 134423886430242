import { axiosAccountInstance } from "api/Request";
import { getSearchUrl } from "helpers/utils";



export const getScheduledInterviews = (status,searchData) => (dispatch) => {
    
  let url = `/interview?status=${status}`
  url = getSearchUrl(url,searchData);
  const beginAction = status === 'scheduled' ? "SCHEDULED_INTERVIEW_LIST_BEGINS" : "ALL_INTERVIEW_LIST_BEGINS";
  const successAction = status === 'scheduled' ? "SCHEDULED_INTERVIEW_LIST_SUCCESS" : "ALL_INTERVIEW_LIST_SUCCESS";
  const failureAction = status === 'scheduled' ? "SCHEDULED_INTERVIEW_LIST_FAILURE" : "ALL_INTERVIEW_LIST_FAILURE";
    debugger
  dispatch({
    type: beginAction,
  });

  axiosAccountInstance
    .get(url)
    .then((result) => {
      // dispatching success
      dispatch({
        type: successAction,
        payload: result.data.body.data,
      });
      // if (result.data.statusCode === 200) {
      //   return dispatch({
      //     type: "SCHEDULED_INTERVIEW_LIST_SUCCESS",
      //     payload: result.data.body.data,
      //   });
      // }
    })
    
    .catch((err) => {
      dispatch({
        type: failureAction,
        payload: "FALIURE",
      });
    });
};

