// import React, { useEffect, useState } from "react";
import ScheduledInterviewListCard from "./AllInterviewListCard";
import { Row, Col, Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "../../../../../components/pagination/Pagination"
import { getScheduledInterviews } from "redux/actions/super-admin/ScheduledInterviewAction";
import AllInterviewListCard from "./AllInterviewListCard";
import { useState } from "react";
import CustomFilter from "components/customFilter";
import SearchIcon from "../../../../../assets/img/icons/customIcons/searchicondark.svg"
import moment from "moment";
function AllInterviewList({
//   handleEmployeeSearchOnChange,
//   handleSearch,
  // searchFormData,
//   setSearchFormData,
  data,
  fromSuperAdmin,
}) {
  const [searchFormData, setSearchFormData] = useState({
    status: null,
    page: null,
    fromDate: null,
    toDate: null,
    search: null,
    limit: null,
    // name: null,
  });
  const [tableHeight, setTableHeigth] = useState(300);
  const [isFilter, setIsFilter] = useState(false);
  const dispatch = useDispatch();
  const clearDate = () => {
    const data = { ...searchFormData, fromDate: null, toDate: null };
    setSearchFormData(data);
    dispatch( getScheduledInterviews("",data))
  };
  const handleEmployeeSearchOnChange = (e, v) => {
    setSearchFormData({ ...searchFormData, [e]: v });
  };
  const handleSearch = (e) => {
    e.preventDefault();
    dispatch( getScheduledInterviews("",searchFormData))
    // employeeListing("listing", searchFormData, companyId);
  };

  const handleSearchOnChange = (name, val) => {
    if (name === "name") {
      let formData = { ...searchFormData, name: val };
      setSearchFormData(formData);
      dispatch( getScheduledInterviews("", { ...searchFormData, name: val }))
      // employeeListing("listing", { ...searchFormData, name: val }, companyId);
    } else {
      let formData = { ...searchFormData, search: val };
      setSearchFormData(formData);
      dispatch( getScheduledInterviews("", { ...searchFormData, search: val }))
      // employeeListing("listing", { ...searchFormData, search: val }, companyId);
    }
  };

  const handleSearchOnSelect = (value, name) => {
    let val =
    name === "date"
    ? {
        ...searchFormData,
        fromDate: moment(value.startDate).format("YYYY-MM-DD"),
        toDate: moment(value.endDate).format("YYYY-MM-DD"),
      }
    : { ...searchFormData, search: value };
    setSearchFormData(val);

    dispatch( getScheduledInterviews("",val))

    // employeeListing("listing", formData, companyId);
  };
  const handlePagination = (tab) => {
    debugger

    const finalData = {
      ...searchFormData,
      page: tab,
    };

    dispatch( getScheduledInterviews("",finalData))
  };
  const scheduledInterviewListLoader = useSelector(
    (state) => state.ScheduledInterviewReducer?.allInterviews?.loader)
    const scheduledInterviewListResult = useSelector(
      (state) => state.ScheduledInterviewReducer?.allInterviews.result
    );
    console.log("Total Count:", scheduledInterviewListResult.itemCount);
  return(
    <>
     <Container className="mt-2 main-contentarea" fluid>
          <Row>
           
            <Col id="tableDiv" md={isFilter ? "9" : "12"}>
            <div className="d-flex">
              <Col md="10" className="d-flex">
                <h3 class="tab-title">All Interviews</h3>
                {/* {empIdChip && (
                  <div class="chip ml-3">
                    {notification?.empId}
                    <span onClick={clearChip} class="closebtn">
                      &times;
                    </span>
                  </div>
                )} */}
              </Col>
              <Col md="2">
                <div className="common-outer-filter">
                {/* <button
                      type="button"
                      onClick={listResumeRequests}
                      className={"btn  mr-3"}
                    >
                     
                    </button> */}
                  <div className="btn-group new-cutom-filter ">
                   
                    <button
                      type="button"
                      onClick={() => setIsFilter(!isFilter)}
                      className={"btn dropdown-toggle mr-3"}
                    >
                      <img alt="" src={SearchIcon} width={"18px"} />
                    </button>
                  </div>
                </div>
              </Col>
            </div>
              {/* <Row> */}
                {/* <Col md="10">
                  <div className="d-flex justify-content-between">
                    <h3 class="tab-title">Interview Listing</h3>
                  </div>
                </Col> */}

              {/* </Row> */}

              <AllInterviewListCard
                // searchFormData={{ ...searchFormData, page: tab }}
                // companyId={companyId}
                loader={scheduledInterviewListLoader}
              />

            

        <div
          style={{
            display: scheduledInterviewListLoader ? "none" : "contents",
          }}
        >
          
          <PaginationComponent
            totalCount={scheduledInterviewListResult?.itemCount}
            handlePagination={handlePagination}
            activePage={scheduledInterviewListResult?.activePage}
          />
        </div>
            </Col>
            <Col
                md="3"
                style={{
                  height: tableHeight,
                  minHeight: "308px",
                  display: isFilter ? "block" : "none",
                }}
              >
                <CustomFilter
                  tableHeight={tableHeight}
                  isFilter={isFilter}
                  setIsFilter={setIsFilter}
                  clearDate={clearDate}
                  type="interview"
                  handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                  handleSearch={handleSearch}
                  handleSearchOnSelect={handleSearchOnSelect}
                  handleSearchOnChange={handleSearchOnChange}
                />
              </Col>
            
          </Row>
        </Container>
    </>
  )
}

export default AllInterviewList;