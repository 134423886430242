import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { setCompanyApprovalInfo } from "redux/actions/super-admin/CompanyApprovalAction";

// import CompanyApprovalList from "./compnay-approval-requests/CompanyApprovalList";
// import CompanyApprovedList from "./company-approved-requests/CompanyApprovedList";
import { setCompanyApprovedRequestsInfo } from "redux/actions/super-admin/ComapnyApprovedAction";
// import CompanyRejectedList from "./company-rejected-requests/CompanyRejectedList";
import { setCompanyRejectedInfo } from "redux/actions/super-admin/CompanyRejectedAction";
import DashboardHeader from "components/Headers/DashboardHeader";
// import { fetchRole } from "helpers/storageData";
// import pendingIcon from "../../../../assets/img/icons/customIcons/pendingIcon.svg";
import approvedIcon from "../../../../assets/img/icons/customIcons/maps-and-flags.svg";
import scheduledIcon from "../../../../assets/img/icons/customIcons/extend-booking-svg.png";
import ScheduledInterviewList from "./scheduled-interviews/ScheduledInterviewList";
import AllInterviewList from "./all-interview-list/AllInterviewList";

function CompanyCointainer() {
  // const role = fetchRole();

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("1");
  const [searchFormData, setSearchFormData] = useState({
    status:null,
    fromDate: null,
    toDate: null,
    search: null,
  });

  const handleClearSearch = () => {
    setSearchFormData({
      status:null,
      fromDate: null,
      toDate: null,
      search: null,
    });
  };

  const approvedCompanyResult = useSelector(
    (state) => state.CompanyApprovedReducer.result
  );
  const { itemCount: approvedCompanyCount } = approvedCompanyResult;

  // const pendingApprovalCompanyResult = useSelector(
  //   (state) => state.CompanyApprovalReducer.result
  // );
  // const { itemCount: pendingCompanyCount } = pendingApprovalCompanyResult;

  const rejectedCompanyResult = useSelector(
    (state) => state.CompanyRejectedReducer.result
  );
  const { itemCount: rejectedCompanyCount } = rejectedCompanyResult;

  const handleEmployeeSearchOnChange = (e, v) => {
    setSearchFormData({ ...searchFormData, [e]: v });
  };

  const getCompanyListRequests = (type, searchData) => {
    dispatch(setCompanyApprovalInfo(type, searchData));
  };

  const getApprovedCompanyListInfo = (type, searchData) => {
    dispatch(setCompanyApprovedRequestsInfo(type, searchData));
  };
  const getRejectedCompanyListInfo = (type, searchData) => {
    dispatch(setCompanyRejectedInfo(type, searchData));
  };
  useEffect(() => {
    // getCompanyListRequests("pending");
    getApprovedCompanyListInfo("approved");
    getRejectedCompanyListInfo("deleted");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    switch (activeTab) {
      case "2":
        getCompanyListRequests("pending", searchFormData);
        break;
      case "1":
        getApprovedCompanyListInfo("approved", searchFormData);
        break;
      case "3":
        getRejectedCompanyListInfo("deleted", searchFormData);
        break;

      default:
        break;
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      switch (tab) {
        case "2":
          getCompanyListRequests("pending");
          handleClearSearch();
          break;
        case "1":
          getApprovedCompanyListInfo("approved");
          handleClearSearch();
          break;
        case "3":
          getRejectedCompanyListInfo("deleted");
          handleClearSearch();
          break;
        default:
          break;
      }

      setActiveTab(tab);
    }
  };
  const data = [
    {
      title: "Interview Details",
      tabId: "1",
      count: approvedCompanyCount,
      icon: approvedIcon,
      color: "green",
    },
   
   
    {
      title: "Scheduled Interviews",
      tabId: "2",
      // count: rejectedCompanyCount,
      icon: scheduledIcon,
      color: "pink",
    },
  ];
  return (
    <div>
      <DashboardHeader
        tabCards={data}
        selectedCard={(tabId) => toggle(tabId)}
        activeTab={activeTab}
      />
      <Container className="mt-4 main-contentarea container-fluid" fluid>
        <div className="custom-tabs">
         
          
          {/* {activeTab === "3" && (
            <CompanyRejectedList
              handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
              handleSearch={handleSearch}
              searchFormData={searchFormData}
              setSearchFormData={setSearchFormData}
              data={"Company Deleted"}
            />
          )} */}
          {activeTab === "1" && (
            <AllInterviewList
              // handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
              // handleSearch={handleSearch}
              searchData={searchFormData}
              // setSearchFormData={setSearchFormData}
              data={"Scheduled Interviews"}
            />
          )}
           {activeTab === "2" && (
            <ScheduledInterviewList
              // handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
              // handleSearch={handleSearch}
              // searchFormData={searchFormData}
              // setSearchFormData={setSearchFormData}
              data={"Scheduled Interviews"}
            />
          )}
        </div>
      </Container>
    </div>
  );
}

export default CompanyCointainer;
